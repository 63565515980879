
import { defineComponent, onMounted, computed, ref, reactive, onBeforeMount } from "vue";
import Vue from 'vue';
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import bootstrap from "bootstrap";
import ApiService from "@/core/services/ApiService";
import router from "@/router";

export default defineComponent({
  name: "agent-list",
  components: {
  },
  setup() {
    const agents = reactive<any>([]);

    const getAgents = async () => {
        await new Promise((resolve, reject) => {  
            ApiService.setHeader();          
            ApiService.get("agent")
                .then(({ data }) => {
                    agents.value = data.data;
                    resolve(data.data);
                })
                .catch(({ response }) => {
                    reject(new Error ('getAgents error => '+response.data.errors));
                });
        });
    }

    const getFilteredAgentList = () =>{
      if(agents.value){
        return agents.value.filter(data => !search 
                                  || data.name.toLowerCase().includes(search.searchValue.toLowerCase())
                                  || data.phone.toLowerCase().includes(search.searchValue.toLowerCase())
                                  || data.email.toLowerCase().includes(search.searchValue.toLowerCase()));
      }

      return [];
    }

    const pageSize = ref(20);
    let page = reactive({pageValue : 1});

    const pageSizes = computed(()=>{
      let sizes = [20];
      let curr_length = getFilteredAgentList().length;

      if(curr_length > 20){
        sizes.push(50);
        if(curr_length >50){
          let i = 0;
          while(i<curr_length){
            i+=100;
            sizes.push(i);
          }
        }
      }

      return sizes;
    })    

    const pagedTableDate = computed(()=>{
      if(agents.value){
          return getFilteredAgentList().slice(pageSize.value * page.pageValue - pageSize.value, pageSize.value * page.pageValue);
      }
      else{
          return [];
      }
     })

    const search = reactive({searchValue : ''});

    const setPage = (val) => {
      page.pageValue = val;
    }

    const handleSizeChange = (val: number) => {
      pageSize.value = val; 
    }

    const filterActive = (value, row) => {
      return row.is_active === value
    }

    const selectAgent= (row) =>{
      router.push({ name: "agent-detail", params: { id: row.id } });
    }

    const openCreateAgent= () =>{
      router.push({ name: "agent-detail", params: { id: 0 } });
    }

    onBeforeMount(async() => {
      await getAgents();
    })

    onMounted(() => {
      setCurrentPageBreadcrumbs("Agent", ["Management"]);
    });

    return {
      setPage,
      pagedTableDate,
      pageSize,
      search,
      filterActive,
      selectAgent,
      agents,
      openCreateAgent,
      pageSizes,
      handleSizeChange,
      getFilteredAgentList
    };
  },
});
